import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import className from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    window.pageYOffset < 100 ? setVisible(true) : setVisible(false);
  };

  return (
    <>
      <a
        className={className("go-to-top", {
          "d-none": visible,
          "d-block": !visible,
        })}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
      </a>
      <div className="position-relative">
        <Container>
          <Link to="/contact-us">
            <div className="footer-ribbon">Get In Touch</div>
          </Link>
        </Container>
        <LazyLoadImage alt="img" src="/icons/footer.png" className="bg-img" />
        <div className="pt-5 mx-lg-5 mx-3">
          <Row
            className="g-3 g-lg-5 h-100"
            style={{ borderBottom: "1px solid #686b6e", fontSize: "14px" }}
          >
            <Col xs="auto" className="mb-3">
              <LazyLoadImage
                alt="img"
                src="/icons/logo-white.png"
                width="150"
                className="d-none d-lg-block"
              />
            </Col>

            <Col lg="auto" className="mt-auto">
              <div className="mb-3">
                <div className="d-lg-inline me-3 mb-2">
                  <a
                    href="tel:020 67678099"
                    className="text-white-50 text-decoration-none"
                  >
                    <span className="text-light">
                      <LazyLoadImage
                        alt="img"
                        src="/icons/social-media-icons/contact.png"
                        width="20"
                        height="20"
                        className="me-2"
                      />
                      020 67678099
                    </span>
                  </a>
                </div>
                <div className="d-inline me-3 mb-2">
                  <a
                    href="mailto:insights@autoflowtech.com"
                    className="text-light text-decoration-none"
                  >
                    <LazyLoadImage
                      alt="img"
                      src="/icons/social-media-icons/email.png"
                      width="20"
                      height="20"
                      className="me-2"
                    />
                    <span>insights@autoflowtech.com</span>
                  </a>
                </div>
                <div className="d-lg-inline mt-2 ms-4 m-lg-0">
                  <span className="text-light">
                    <LazyLoadImage
                      alt="img"
                      src="/icons/india.svg"
                      width="15"
                      className="me-1"
                    />
                    IN
                    <span
                      className="mx-1"
                      style={{ color: "orange", fontWeight: 900, fontSize: 16 }}
                    >
                      |
                    </span>
                    <LazyLoadImage
                      alt="img"
                      src="/icons/united-states.svg"
                      width="15"
                      className="me-1"
                    />
                    US
                    <span
                      className="mx-1"
                      style={{ color: "orange", fontWeight: 900, fontSize: 16 }}
                    >
                      |
                    </span>
                    <LazyLoadImage
                      alt="img"
                      src="/icons/united-kingdom.svg"
                      width="15"
                      className="me-1"
                    />
                    UK
                  </span>
                </div>
              </div>
            </Col>
            <Col className="position-relative">
              <Row className="justify-content-lg-end justify-content-between">
                <Col xs="auto">
                  <div className="mb-3">
                    <div className="mb-2">
                      <strong className="text-white title-font-size">
                        Connect with us
                      </strong>
                    </div>
                    <div>
                      {[
                        {
                          img: "facebook.png",
                          action: "https://www.facebook.com/autoflowtech/",
                        },
                        {
                          img: "instagram.png",
                          action:
                            "https://www.instagram.com/autoflowtech/?hl=en",
                        },
                        {
                          img: "linkedin.png",
                          action:
                            "https://in.linkedin.com/company/autoflowtech",
                        },
                        {
                          img: "youtube.png",
                          action:
                            "https://www.youtube.com/channel/UCwiQNDGqNJwE-4Nhz7-IG_g",
                        },
                      ].map((ele, index) => {
                        return (
                          <a
                            className="cursor-pointer me-2"
                            key={index}
                            href={ele.action}
                            target="_blank"
                          >
                            <LazyLoadImage
                              alt="img"
                              src={`/icons/social-media-icons/${ele.img}`}
                              width="25"
                              height="25"
                            />
                          </a>
                        );
                      })}
                    </div>
                    <div className="mb-2">
                    <a
                    className=" btn-sm p-0 shadow-none"
                    style={{ fontSize: 10,color:'white' }} target="_blank"
                    href="/privacy.html"
                  >
                    Privacy Policy
                  </a>

                    
                    </div>
                  </div>
                </Col>
                <Col className="d-flex d-lg-none">
                  <LazyLoadImage
                    alt="img"
                    src="/icons/logo-white.png"
                    width="125"
                    className="ms-auto mb-3"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="pt-3 pb-5 text-white-50 h-100 text-center">
            Copyright @ {new Date().getFullYear()} Autoflow Technologies Private
            Limited
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
