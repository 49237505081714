import "bootstrap/dist/css/bootstrap.min.css";
import "imagehover.css/css/imagehover.min.css";
import "react-vertical-timeline-component/style.min.css";
import "./App.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { lazy, Suspense } from "react";
import "react-multi-carousel/lib/styles.css";
import { HashRouter, Route, Switch } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import data from "./db.json";
import SquareLoader from "react-spinners/SquareLoader";
import { override } from "./config";
import { ToastProvider } from "react-toast-notifications";
import FloatingWhatsApp from "react-floating-whatsapp";

// lazy

const Home = lazy(() => import("./Containers/Home"));
const Products = lazy(() => import("./Containers/Products"));
const Services = lazy(() => import("./Containers/Services"));
const Careers = lazy(() => import("./Containers/Careers"));
const Blogs = lazy(() => import("./Containers/Blogs"));
const AboutUs = lazy(() => import("./Containers/AboutUs"));
const ContactUs = lazy(() => import("./Containers/ContactUs"));

const ProductAndServiceDetails = lazy(() =>
  import("./Components/ProductAndServiceDetails")
);
const BlogDetails = lazy(() => import("./Components/BlogDetails"));

// Can be a string as well. Need to ensure each key-value pair ends with ;

const App = () => {
  return (
    <ToastProvider
      placement="bottom-center"
      autoDismissTimeout={3000}
      autoDismiss={true}
    >
      <Suspense
        fallback={<SquareLoader color={"#c26e03"} css={override} size={50} />}
      >
        <HashRouter>
          {/* <FloatingWhatsApp
            phoneNumber="020 67678099"
            accountName="AutoFlow Technologies"
            allowClickAway
            avatar="/icons/fevicon-logo.png"
            className="floating-whatsapp-btn"
            notification
            notificationDelay={60000} // 1 minute
            notificationSound
            allowClickAway
            allowEsc
          /> */}
          <Header />
          <Switch>
            <Route exact path="/" render={() => <Home data={data} />} />
            <Route
              exact
              path="/products"
              render={() => <Products products={data.products} />}
            />
            <Route
              exact
              path="/services"
              render={() => <Services services={data.services} />}
            />
            <Route
              path="/careers"
              render={() => (
                <Careers current_openings={data.current_openings} />
              )}
            />
            <Route
              exact
              path="/blogs"
              render={() => <Blogs blogs={data.blogs} />}
            />
            <Route
              path="/about-us"
              render={() => <AboutUs leaders={data.leaders} />}
            />
            <Route path="/contact-us" render={() => <ContactUs />} />
            <Route
              path={[
                "/products/details/:id/:type",
                "/services/details/:id/:type",
              ]}
              component={ProductAndServiceDetails}
            />
            <Route path="/blogs/blog-details/:blogId" component={BlogDetails} />
          </Switch>
          <Footer />
        </HashRouter>
      </Suspense>
    </ToastProvider>
  );
};

export default App;
