import {
  faTimes,
  faBars,
  faHome,
  faListAlt,
  faWrench,
  faLaptopCode,
  faTag,
  faBlog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import className from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Header = () => {
  const [toggleBtn, setToggleBtn] = useState(false);
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const blogDetailUrl = useRouteMatch("/blogs/blog-details/:blogId");
  const productDetailUrl = useRouteMatch("/products/details/:id/:type");
  const serviceDetailUrl = useRouteMatch("/services/details/:id/:type");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    setVisible(true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    window.pageYOffset < 100 ? setVisible(true) : setVisible(false);
  };

  const closeSideMenu = () => {
    if (toggleBtn) {
      setToggleBtn(false);
      document.getElementById("side-menu").style.width = "0";
    }
  };

  const toggleFuction = () => {
    const sideMenu = document.getElementById("side-menu");
    setToggleBtn(!toggleBtn);
    toggleBtn ? (sideMenu.style.width = "0") : (sideMenu.style.width = "80%");
  };

  const links = (
    <>
      <Nav className="ms-auto h-100">
        <span className="position-relative d-lg-flex">
          <div
            className="position-absolute bg-dark country-logo"
            style={{
              right: 0,
              borderRadius: "0 0 10px 10px",
              padding: "0px 10px",
            }}
          >
            <small style={{ fontSize: 10 }} className="text-light">
              <LazyLoadImage
                alt="img"
                src="/icons/india.svg"
                width="12"
                className="me-1"
              />
              IN
              <span
                className="mx-1"
                style={{ color: "orange", fontWeight: 900 }}
              >
                |
              </span>
              <LazyLoadImage
                alt="img"
                src="/icons/united-states.svg"
                width="12"
                className="me-1"
              />
              US
              <span
                className="mx-1"
                style={{ color: "orange", fontWeight: 900 }}
              >
                |
              </span>
              <LazyLoadImage
                alt="img"
                src="/icons/united-kingdom.svg"
                width="12"
                className="me-1"
              />
              UK
            </small>
          </div>
          <NavLink
            onClick={closeSideMenu}
            to="/"
            exact
            activeClassName="activeLink"
            className="fw-bold  my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold"
          >
            <FontAwesomeIcon className="me-2 d-lg-none" icon={faHome} />
            Home
          </NavLink>
          <NavLink
            onClick={closeSideMenu}
            to="/products"
            activeClassName="activeLink"
            className="fw-bold my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold"
          >
            <FontAwesomeIcon className="me-2 d-lg-none" icon={faListAlt} />
            Products
          </NavLink>
          <NavLink
            onClick={closeSideMenu}
            to="/services"
            activeClassName="activeLink"
            className="fw-bold  my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold"
          >
            <FontAwesomeIcon className="me-2 d-lg-none" icon={faWrench} />
            Services
          </NavLink>
          <NavLink
            onClick={closeSideMenu}
            to="/careers"
            activeClassName="activeLink"
            className="fw-bold  my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold"
          >
            <FontAwesomeIcon className="me-2 d-lg-none" icon={faLaptopCode} />
            Careers
          </NavLink>
       {   <NavLink
            onClick={closeSideMenu}
            to="/blogs"
            activeClassName="activeLink"
            className="fw-bold  my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold"
          >
            <FontAwesomeIcon className="me-2 d-lg-none" icon={faBlog} />
            Blogs
          </NavLink> }
          <NavLink
            onClick={closeSideMenu}
            to="/about-us"
            activeClassName="activeLink"
            className="fw-bold my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold"
          >
            <FontAwesomeIcon className="me-2 d-lg-none" icon={faTag} />
            About
          </NavLink>
          <NavLink onClick={closeSideMenu} to="/contact-us" className="my-auto">
            <Button
              className="btn-success shadow-none rounded-pill px-4 mt-3 m-lg-0 contact-us-gradient"
              size="sm"
            >
              Contact us
            </Button>
          </NavLink>
        </span>
      </Nav>
    </>
  );

  return (
    <>
      {
        // no stricky header effect
        path === "/blogs" ||
        path === blogDetailUrl?.url ||
        path === productDetailUrl?.url ||
        path === serviceDetailUrl?.url ? (
          <Navbar
            className="navbar px-lg-5 px-3 bg-white"
            sticky="top"
            expand="lg"
          >
            <LazyLoadImage
              alt="img"
              src="/icons/grid-bar.png"
              width="100%"
              height="5"
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
            />
            <Navbar.Brand href="#">
              <LazyLoadImage
                alt="img"
                src="/icons/logo.png"
                width="135"
                className="logo-width"
              />
            </Navbar.Brand>
            <Button
              className="d-block d-lg-none shadow-none text-dark"
              variant="link"
              onClick={toggleFuction}
            >
              <FontAwesomeIcon size="lg" icon={toggleBtn ? faTimes : faBars} />
            </Button>
            <Navbar.Collapse>{links}</Navbar.Collapse>
            <div
              id="side-menu"
              className="sidenav bg-1 position-fixed h-100 d-block d-md-none"
            >
              <div className="p-3">
                <Navbar.Brand className="mb-3 d-block">
                  <LazyLoadImage
                    alt="img"
                    src="/icons/logo-white.png"
                    width="100"
                  />
                </Navbar.Brand>
                {links}
              </div>
            </div>
          </Navbar>
        ) : (
          <Navbar
            id="header"
            className={className("navbar px-lg-5 px-3 header", {
              "bg-white shadow": !visible,
            })}
            variant="light"
            expand="lg"
          >
            <LazyLoadImage
              alt="img"
              className={className({ "opacity-0": visible })}
              src="/icons/grid-bar.png"
              width="100%"
              height="5"
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
            />
            <Navbar.Brand href="#">
              <LazyLoadImage
                alt="img"
                src={visible ? "/icons/logo-white.png" : "/icons/logo.png"}
                width="135"
                className="logo-width"
              />
            </Navbar.Brand>
            <Button
              className={className("d-block d-lg-none shadow-none", {
                "text-3": !visible,
                "text-white": visible,
                "text-dark": !visible,
              })}
              variant="link"
              onClick={toggleFuction}
            >
              <FontAwesomeIcon size="lg" icon={toggleBtn ? faTimes : faBars} />
            </Button>
            {/* desktop navbar */}
            <Navbar.Collapse>
              <Nav className="ms-auto h-100">
                <span className="position-relative d-lg-flex">
                  <div
                    className={className("position-absolute", {
                      "bg-light": visible,
                      "bg-dark": !visible,
                    })}
                    style={{
                      top: -25,
                      right: 0,
                      borderRadius: "0 0 10px 10px",
                      padding: "0px 10px",
                    }}
                  >
                    <small
                      style={{ fontSize: 10 }}
                      className={className({
                        "text-dark": visible,
                        "text-light": !visible,
                      })}
                    >
                      <LazyLoadImage
                        alt="img"
                        src="/icons/india.svg"
                        width="12"
                        className="me-1"
                      />
                      IN
                      <span
                        className="mx-1"
                        style={{ color: "orange", fontWeight: 900 }}
                      >
                        |
                      </span>
                      <LazyLoadImage
                        alt="img"
                        src="/icons/united-states.svg"
                        width="12"
                        className="me-1"
                      />
                      US
                      <span
                        className="mx-1"
                        style={{ color: "orange", fontWeight: 900 }}
                      >
                        |
                      </span>
                      <LazyLoadImage
                        alt="img"
                        src="/icons/united-kingdom.svg"
                        width="12"
                        className="me-1"
                      />
                      UK
                    </small>
                  </div>
                  <NavLink
                    onClick={closeSideMenu}
                    to="/"
                    activeClassName="activeLink"
                    exact
                    className={className(
                      "my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold",
                      { "text-white": visible }
                    )}
                  >
                    <FontAwesomeIcon className="me-2 d-lg-none" icon={faHome} />
                    Home
                  </NavLink>
                  <NavLink
                    onClick={closeSideMenu}
                    to="/products"
                    activeClassName="activeLink"
                    className={className(
                      "my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold",
                      { "text-white": visible }
                    )}
                  >
                    <FontAwesomeIcon
                      className="me-2 d-lg-none"
                      icon={faListAlt}
                    />
                    Products
                  </NavLink>
                  <NavLink
                    onClick={closeSideMenu}
                    to="/services"
                    activeClassName="activeLink"
                    className={className(
                      "my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold",
                      { "text-white": visible }
                    )}
                  >
                    <FontAwesomeIcon
                      className="me-2 d-lg-none"
                      icon={faWrench}
                    />
                    Services
                  </NavLink>
                  <NavLink
                    onClick={closeSideMenu}
                    to="/careers"
                    activeClassName="activeLink"
                    className={className(
                      "my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold",
                      { "text-white": visible }
                    )}
                  >
                    <FontAwesomeIcon
                      className="me-2 d-lg-none"
                      icon={faLaptopCode}
                    />
                    Careers
                  </NavLink>
               {    <NavLink
                    onClick={closeSideMenu}
                    to="/blogs"
                    activeClassName="activeLink"
                    className={className(
                      "my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold",
                      { "text-white": visible }
                    )}
                  >
                    <FontAwesomeIcon className="me-2 d-lg-none" icon={faBlog} />
                    Blogs
                  </NavLink> }
                  <NavLink
                    onClick={closeSideMenu}
                    to="/about-us"
                    activeClassName="activeLink"
                    className={className(
                      "my-auto px-lg-3 px-0 text-decoration-none nav-link fw-bold",
                      { "text-white": visible }
                    )}
                  >
                    <FontAwesomeIcon className="me-2 d-lg-none" icon={faTag} />
                    About
                  </NavLink>
                  <NavLink
                    onClick={closeSideMenu}
                    to="/contact-us"
                    className="my-auto"
                  >
                    <Button
                      className="btn-success shadow-none rounded-pill contact-us-gradient px-4 mt-3 m-lg-0"
                      size="sm"
                    >
                      Contact us
                    </Button>
                  </NavLink>
                </span>
              </Nav>
            </Navbar.Collapse>

            {/* Mobile navbar */}
            <div
              id="side-menu"
              className="sidenav bg-1 position-fixed h-100 d-block d-md-none"
            >
              <div className="p-3">
                <Navbar.Brand className="mb-3 d-block">
                  <LazyLoadImage
                    alt="img"
                    src="/icons/logo-white.png"
                    width="100"
                  />
                </Navbar.Brand>
                {links}
              </div>
            </div>
          </Navbar>
        )
      }
    </>
  );
};

export default Header;
